<template>
  <el-form :disabled="!allowToPair || isReadOnly" v-for="(label, action) in trackPointActions" :key="action">
    <div class="grid grid-cols-12 border-b gap-4 py-8">
      <div class="col-span-3 p-1">
        <div class="font-bold whitespace-nowrap">
          {{ label }}
        </div>
      </div>
      <div class="col-span-9">
        <!-- Items -->
        <div class="grid grid-cols-5 items-center gap-4 pb-1" v-for="item in trackingPointDataShown" :key="item.key">
          <div class="col-span-1">
            <div class="text-right">{{ item.value.name }} {{ `(${item.key})` }}</div>
          </div>
          <div class="col-span-2">
            <el-radio-group
              v-model="actionTrackPoints[action][item.key]"
              @change="onChangePairOrUnpairTrackingPoints(action, item.key, $event)"
            >
              <el-radio label="mandatory" :disabled="isDisabledPairUnpairMandatoryOption(item.key, action)">
                {{ $t('barcode_type.mandatory') }}
              </el-radio>
              <el-radio label="available">
                {{ $t('barcode_type.available') }}
              </el-radio>
              <el-radio label="unavailable">
                {{ $t('barcode_type.unavailable') }}
              </el-radio>
            </el-radio-group>
          </div>
          <div v-if="action == 'unpairing'" class="col-span-2 flex">
            <div>
              <el-tooltip placement="top" effect="dark">
                <template v-slot:content>
                  {{ $t('auto_unpair_note') }}
                </template>

                <el-checkbox
                  v-model="actionUnpair[action][item.key].autoUnpairingSetting.isEnable"
                  @change="updateAutoUnpairSetting(action, item.key, 'isEnable', $event)"
                  :disabled="!actionUnpair[action][item.key].isMandatory && !actionUnpair[action][item.key].isAvailable"
                >
                  {{ $t('barcode_type.auto_unpair') }}
                </el-checkbox>
              </el-tooltip>
            </div>
            <div class="ml-3" v-if="actionUnpair[action][item.key].autoUnpairingSetting.isEnable ?? false">
              <el-tooltip placement="top" effect="dark">
                <template v-slot:content>
                  {{ $t('is_unpair_descendent_note') }}
                </template>

                <el-checkbox
                  :disabled="!actionUnpair[action][item.key].isMandatory && !actionUnpair[action][item.key].isAvailable"
                  v-model="actionUnpair[action][item.key].autoUnpairingSetting.isUnpairDescendent"
                  @change="updateAutoUnpairSetting(action, item.key, 'isUnpairDescendent', $event)"
                >
                  {{ $t('barcode_type.is_unpair_descendent') }}
                </el-checkbox>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="border-b border-gray-400 border-solid"></div>
  </el-form>
</template>
<script lang="ts">
import { fieldRestriction2String, restrictionRule } from '@/utils/helpers'
import { ERestrictionOption } from 'smartbarcode-web-core/src/utils/enums'
import { IAutoUnpairSetting, IFieldRestriction, ITrackPointKeyVal } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop, PropSync } from 'vue-property-decorator'

@Options({
  emits: ['update:pairing', 'update:unpairing'],
  name: 'PairedAllowanceBlock',
})
export default class PairedAllowanceBlock extends Vue {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() trackingPointDataShown!: ITrackPointKeyVal[]
  @PropSync('pairing', { type: Object }) _pairing?: { [index: string]: IFieldRestriction }
  @PropSync('unpairing', { type: Object }) _unpairing?: { [index: string]: IFieldRestriction }
  @Prop({ type: Boolean, default: false }) readonly allowToPair?: boolean
  @Prop({ type: Boolean, default: false }) readonly isReadOnly?: boolean

  get trackPointActions() {
    return {
      pairing: this.$t('barcode_type.pair_tracking_point'),
      unpairing: this.$t('barcode_type.unpair_tracking_point'),
    }
  }

  get actionTrackPoints(): Record<'pairing' | 'unpairing', Record<string, string>> {
    return {
      pairing: Object.fromEntries(
        this.trackingPointDatasArr.map((v) => [v.key, fieldRestriction2String((this._pairing ?? {})[v.key])])
      ),
      unpairing: Object.fromEntries(
        this.trackingPointDatasArr.map((v) => [v.key, fieldRestriction2String((this._unpairing ?? {})[v.key])])
      ),
    }
  }

  get actionUnpair(): Record<'unpairing', Record<string, IFieldRestriction>> {
    return {
      unpairing: Object.fromEntries(
        this.trackingPointDatasArr.map((v) => {
          let unpairData: IFieldRestriction = {
            isMandatory: false,
            isAvailable: false,
            autoUnpairingSetting: {
              isEnable: false,
              isUnpairDescendent: false,
            },
          }
          if (this._unpairing && this._unpairing[v.key]) {
            unpairData = this._unpairing[v.key]
          }
          const processedUnpairingData: IFieldRestriction = {
            ...unpairData,
            autoUnpairingSetting: {
              isEnable: unpairData?.autoUnpairingSetting?.isEnable || false,
              isUnpairDescendent: unpairData?.autoUnpairingSetting?.isUnpairDescendent || false,
            },
          }
          return [v.key, processedUnpairingData]
        })
      ),
    }
  }

  updateAutoUnpairSetting(action: 'unpairing', key: string, setting: keyof IAutoUnpairSetting, event: unknown) {
    const autoUnpairingSetting = this.actionUnpair[action]?.[key]?.autoUnpairingSetting
    if (autoUnpairingSetting && setting in autoUnpairingSetting) {
      autoUnpairingSetting[setting] = event as boolean
      if (autoUnpairingSetting[setting] === false && setting === 'isEnable') {
        autoUnpairingSetting.isUnpairDescendent = false
      }
    }
    const processedUnpairingData = { ...this.actionUnpair[action][key] }
    this._unpairing = {
      ...this._unpairing,
      [key]: processedUnpairingData,
    }
  }

  isDisabledPairUnpairMandatoryOption(key: string, action: string): boolean {
    return (
      (action === 'unpairing' && this.actionTrackPoints.pairing[key] === ERestrictionOption.MANDATORY) ||
      (action === 'pairing' && this.actionTrackPoints.unpairing[key] === ERestrictionOption.MANDATORY)
    )
  }

  onChangePairOrUnpairTrackingPoints(type: 'pairing' | 'unpairing', trackPointKey: string, value: string) {
    if (type === 'unpairing') {
      const restrictionRuleValue: IFieldRestriction = restrictionRule(value)

      if (!restrictionRuleValue.isAvailable && !restrictionRuleValue.isMandatory) {
        this._unpairing = {
          ...(this._unpairing && this._unpairing),
          [trackPointKey]: {
            ...restrictionRuleValue,
            autoUnpairingSetting: {
              isEnable: false,
              isUnpairDescendent: false,
            },
          },
        }
      } else {
        const updatedData: IFieldRestriction = {
          ...restrictionRuleValue,
          autoUnpairingSetting: {
            isEnable: this._unpairing?.[trackPointKey]?.autoUnpairingSetting?.isEnable || false,
            isUnpairDescendent: this._unpairing?.[trackPointKey]?.autoUnpairingSetting?.isUnpairDescendent || false,
          },
        }
        this._unpairing = {
          ...(this._unpairing && this._unpairing),
          [trackPointKey]: updatedData,
        }
      }

      return
    }
    this[`_${type}`] = {
      ...(this[`_${type}`] && this[`_${type}`]),
      [trackPointKey]: restrictionRule(value),
    }
  }
}
</script>
