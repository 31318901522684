
import { Options, mixins } from 'vue-class-component'
import { InjectReactive, Prop, PropSync, Watch } from 'vue-property-decorator'
import {
  IBarcodeDefinitionType,
  ICustomValidator,
  ITrackPointKeyVal,
} from 'smartbarcode-web-core/src/utils/types/index'
import ValidateForm from '@/components/mixins/ValidateForm.vue'
import CustomValidatorDialog from '@/components/project/blocks/CustomValidatorDialog.vue'
import {
  COMPARE_METHOD_VALUE,
  CUSTOM_VALIDATION_CONDITION_DEFAULT,
  LOGICAL_OPERATOR_VALUE,
  VALIDATION_EVENT_TYPE_VALUE,
  VALIDATION_RESULT_TYPE_VALUE,
} from '@/utils/constants'
import cloneDeep from 'lodash/cloneDeep'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'

export interface IRule {
  field: string
  fullField: string
  required: boolean
  type: string
  countryCode?: string
  validator: Function
}

@Options({
  components: { CustomValidatorDialog },
  emits: ['update:customValidators'],
  name: 'CustomValidator',
})
export default class CustomValidator extends mixins(ValidateForm) {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @Prop({ type: Object }) readonly barcodeTypes!: Record<string, IBarcodeDefinitionType>
  @Prop({ type: String }) readonly currentBCTypeCode!: string
  @PropSync('customValidators', { type: Array, default: [] })
  _customValidators!: ICustomValidator[]

  currentCustomValidator = {} as ICustomValidator
  currentIndex = -1
  isShowDialog = false

  @Watch('trackingPointDatasArr')
  onTrackPointChange() {
    this._customValidators.forEach((val, idx) => {
      if (val.eventType === VALIDATION_EVENT_TYPE_VALUE.tracked) {
        const endTPRouteAvailable = this.loadTP(val?.trackPointRoute?.from || '')
        const found = endTPRouteAvailable.find((val) => {
          return val.key === this._customValidators[idx].trackPointRoute?.to
        })
        if (!found) {
          this._customValidators.splice(idx, 1)
        }
      }
    })
  }

  loadTP(fromIdx: string) {
    if (isEmpty(fromIdx)) return [] as ITrackPointKeyVal[]

    const keys = Object.keys(
      (this.trackingPointDatasArr.find((tpData) => tpData.key === fromIdx) as ITrackPointKeyVal)?.value
        ?.trackPointForms ?? {}
    )

    return this.trackingPointDatasArr.filter((tpData) => keys.includes(tpData.key))
  }

  isTrackingEventType(eventType: string) {
    return eventType === VALIDATION_EVENT_TYPE_VALUE.tracked
  }

  addCustomValidator() {
    this.currentCustomValidator = {
      eventType: VALIDATION_EVENT_TYPE_VALUE.tracked,
      resultType: VALIDATION_RESULT_TYPE_VALUE.error,
      trackPointRoute: { from: '', to: '' },
      conditionLogicalOperator: LOGICAL_OPERATOR_VALUE.or,
      conditions: [cloneDeep(CUSTOM_VALIDATION_CONDITION_DEFAULT)],
      message: '',
    } as ICustomValidator

    this.currentIndex = -1
    this.isShowDialog = true
  }

  openCustomValidator(idx: number) {
    this.currentIndex = idx
    const customValidator = this._customValidators[idx]
    if (!customValidator.trackPointRoute) {
      customValidator.trackPointRoute = { from: '', to: '' }
    }
    this.currentCustomValidator = customValidator
    this.isShowDialog = true
  }

  closeDialog() {
    this.currentIndex = -1
    this.isShowDialog = false
  }

  deleteCustomValidator(idx: number) {
    this._customValidators.splice(idx, 1)
  }

  saveCustomValidator(data: ICustomValidator) {
    if (this.currentIndex === -1) {
      this._customValidators.push(data)
    } else {
      this._customValidators[this.currentIndex] = data
    }
    this.isShowDialog = false
  }

  getContentValidator(data: ICustomValidator) {
    const logicalOperator =
      data.conditionLogicalOperator.toLowerCase() === LOGICAL_OPERATOR_VALUE.and
        ? this.$t('barcode_type.and')
        : this.$t('barcode_type.or')

    const arrMessage = [] as string[]
    let beginMessage = ''
    let endMessage = ''
    data.conditions.forEach((condition) => {
      const arr = [] as string[]
      const comparisonLogicalOperator =
        condition.comparisonLogicalOperator.toLowerCase() === LOGICAL_OPERATOR_VALUE.and
          ? this.$t('barcode_type.and')
          : this.$t('barcode_type.or')
      condition.comparisons.forEach((item) => {
        const compareMethod =
          item.operator === COMPARE_METHOD_VALUE.equal
            ? this.$t('barcode_type.matched')
            : this.$t('barcode_type.notMatched')
        arr.push(
          this.$t('barcode_type.customValidationMiddle', {
            compareFrom: `<span class="underline leading-5">${item.from}</span>`,
            compareTo: `<span class="underline leading-5">${item.to}</span>`,
            compareMethod: `<span class="underline leading-5">${compareMethod}</span>`,
          })
        )
      })

      arrMessage.push(arr.join(comparisonLogicalOperator))
    })

    if (data.eventType === VALIDATION_EVENT_TYPE_VALUE.tracked) {
      const trackingPointFormName = this.getTrackingPointName(data.trackPointRoute?.from)
      const trackingPointToName = this.getTrackingPointName(data.trackPointRoute?.to)
      beginMessage = this.$t('barcode_type.customValidationTrackingNameStart')
        ? this.$t('barcode_type.customValidationTrackingNameStart', {
            from: `<span class="underline leading-5">${trackingPointFormName}</span>`,
            to: `<span class="underline leading-5">${trackingPointToName}</span>`,
          })
        : ''
      endMessage = this.$t('barcode_type.customValidationTrackingNameEnd')
        ? this.$t('barcode_type.customValidationTrackingNameEnd', {
            from: `<span class="underline leading-5">${trackingPointFormName}</span>`,
            to: `<span class="underline leading-5">${trackingPointToName}</span>`,
          })
        : ''
    } else {
      beginMessage = this.$t('barcode_type.customValidationOtherNameStart')
        ? this.$t('barcode_type.customValidationOtherNameStart', {
            eventType: `<span class="underline leading-5">${this.$t(`barcode_type.${data.eventType}`)}</span>`,
          })
        : ''
      endMessage = this.$t('barcode_type.customValidationOtherNameEnd')
        ? this.$t('barcode_type.customValidationOtherNameEnd', {
            eventType: `<span class="underline leading-5">${this.$t(`barcode_type.${data.eventType}`)}</span>`,
          })
        : ''
    }

    return `${beginMessage ? `${beginMessage}<br/>` : ''}${arrMessage.join(`${logicalOperator}<br/>`)}${
      endMessage ? `<br/>${endMessage}` : ''
    }`
  }

  getTrackingPointName(id?: string) {
    const trackingPointForm = this.trackingPointDatasArr.filter((item) => item.key === id)
    return trackingPointForm?.[0]?.value.name || ''
  }
}
