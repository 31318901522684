
import { openMessage } from '@/utils/utils'
import { validateGAMeasurementId } from '@/utils/validator'
import isEmpty from 'lodash/isEmpty'
import { AVAILABLE_DELIMITERS } from 'smartbarcode-web-core/src/utils/enums'
import { ICameraFeatures } from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Options({
  name: 'ProjectSettingsTab',
})
export default class ProjectSettingsTab extends Vue {
  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  showBarcodeSearchPage = false
  showTrackingCountPage = false
  hasGoogleAnalytics = false
  googleAnalyticsMeasurementId = ''
  allowUnmatchedTracking = false
  allowForcePairing = false
  isAutoRecycleEndTrackingPoint = false
  isRecycleChildren = false
  cameraFeatures: ICameraFeatures = {
    bulkCameraScanResultSetting: {
      isEnable: false,
      delimiter: this.listDelimiters[0],
    },
  } as ICameraFeatures

  get listDelimiters() {
    return AVAILABLE_DELIMITERS
  }

  @Watch('hasGoogleAnalytics')
  onHasGAChanged(checked: boolean) {
    if (!checked) this.googleAnalyticsMeasurementId = ''
  }

  @Watch('$store.state.project.projectDetail.mainInfo.googleAnalyticsMeasurementId')
  loadingGAMeasurementId() {
    this.googleAnalyticsMeasurementId =
      this.$store.state.project?.projectDetail.mainInfo?.googleAnalyticsMeasurementId || ''
    this.hasGoogleAnalytics = !isEmpty(this.googleAnalyticsMeasurementId)
  }

  @Watch('$store.state.project.projectDetail.mainInfo.showBarcodeSearchPage')
  loadingShowBarcodeSearchPage() {
    this.showBarcodeSearchPage = this.$store.state.project?.projectDetail.mainInfo?.showBarcodeSearchPage || false
  }

  @Watch('$store.state.project.projectDetail.mainInfo.showTrackingCountPage')
  loadingShowTrackingCountPage() {
    this.showTrackingCountPage = this.$store.state.project?.projectDetail.mainInfo?.showTrackingCountPage || false
  }

  @Watch('$store.state.project.projectDetail.mainInfo.allowUnmatchedTracking')
  loadingUnmatchingPair() {
    this.allowUnmatchedTracking = this.$store.state.project?.projectDetail.mainInfo?.allowUnmatchedTracking || false
  }

  @Watch('$store.state.project.projectDetail.mainInfo.allowForcePairing')
  loadingAllowForcePairing() {
    this.allowForcePairing = this.$store.state.project?.projectDetail.mainInfo?.allowForcePairing || false
  }

  @Watch('$store.state.project.projectDetail.mainInfo.isAutoRecycleEndTrackingPoint')
  loadingIsAutoRecycleEndTrackingPoint() {
    this.isAutoRecycleEndTrackingPoint =
      this.$store.state.project?.projectDetail.mainInfo?.isAutoRecycleEndTrackingPoint || false
  }

  @Watch('$store.state.project.projectDetail.mainInfo.isRecycleChildren')
  loadingIsRecycleChildren() {
    this.isRecycleChildren = this.$store.state.project?.projectDetail.mainInfo?.isRecycleChildren || false
  }

  @Watch('$store.state.project.projectDetail.mainInfo?.cameraFeatures')
  loadingbulkCameraScanResultSetting() {
    this.cameraFeatures.bulkCameraScanResultSetting.isEnable =
      this.$store.state.project?.projectDetail.mainInfo?.cameraFeatures?.bulkCameraScanResultSetting?.isEnable || false

    this.cameraFeatures.bulkCameraScanResultSetting.delimiter =
      this.$store.state.project?.projectDetail.mainInfo?.cameraFeatures?.bulkCameraScanResultSetting?.delimiter || ''
  }

  validate() {
    if (this.hasGoogleAnalytics && !validateGAMeasurementId(this.googleAnalyticsMeasurementId)) {
      openMessage(this.$t('projects.GA_measurement_id_error_message'), 'error')
      return false
    }

    return true
  }
}
