<template>
  <div class="mt-2 bg-gray-200 rounded-md project-setting-tab overflow-auto">
    <el-form :disabled="isReadOnlyMode">
      <div class="grid grid-cols-7 gap-2 py-8 border-b border-gray-400 border-solid mx-4">
        <div class="col-span-1 py-4 px-1 font-bold">
          {{ $t('projects.additionalFeatures') }}
        </div>
        <div class="col-span-6 p-3">
          <div class="flex flex-col">
            <el-checkbox name="showBarcodeSearchPage" v-model="showBarcodeSearchPage" class="ml-0">
              {{ $t('projects.showBarcodeSearchPage') }}
            </el-checkbox>
            <el-checkbox name="showTrackingCountPage" v-model="showTrackingCountPage" class="ml-0 mt-4">
              {{ $t('projects.showTrackingCountPage') }}
            </el-checkbox>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-7 gap-2 py-8 border-b border-gray-400 border-solid mx-4">
        <div class="col-span-1 py-4 px-1 font-bold">
          {{ $t('projects.pairSetting') }}
        </div>
        <div class="col-span-6 p-3">
          <div class="flex flex-col">
            <el-checkbox name="allowUnmatchedTracking" v-model="allowUnmatchedTracking" class="ml-0">
              {{ $t('projects.allowUnmatchedTracking') }}
            </el-checkbox>
            <el-checkbox name="allowUnmatchedTracking" v-model="allowForcePairing" class="ml-0 mt-4">
              {{ $t('projects.allowForcePairing') }}
            </el-checkbox>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-7 gap-2 py-8 border-b border-gray-400 border-solid mx-4">
        <div class="col-span-1 py-4 px-1 font-bold">
          {{ $t('projects.recycleSetting') }}
        </div>
        <div class="col-span-6 p-3">
          <div class="flex flex-col">
            <el-checkbox name="isAutoRecycleEndTrackingPoint" v-model="isAutoRecycleEndTrackingPoint" class="ml-0">
              {{ $t('projects.isAutoRecycleEndTrackingPoint') }}
            </el-checkbox>
            <el-checkbox name="isRecycleChildren" v-model="isRecycleChildren" class="ml-0 mt-4">
              {{ $t('projects.isRecycleChildren') }}
            </el-checkbox>
          </div>
        </div>
      </div>

      <div class="grid grid-cols-7 gap-2 py-8 mx-4">
        <div class="col-span-1 py-4 px-1 font-bold">
          {{ $t('projects.access_analysis') }}
        </div>
        <div class="col-span-6 p-2">
          <div class="flex flex-row p-1">
            <el-checkbox name="hasGoogleAnalytics" v-model="hasGoogleAnalytics" class="ml-0">
              {{ $t('projects.connect_google_analytics') }}
            </el-checkbox>
          </div>
          <el-form :disabled="!hasGoogleAnalytics">
            <div class="flex flex-row items-center p-1">
              <div class="mr-8">{{ $t('projects.google_analytics_measurement_id') }}</div>
              <div class="ml-4">
                <el-input name="googleAnalyticsMeasurementId" v-model="googleAnalyticsMeasurementId"></el-input>
              </div>
            </div>
          </el-form>
        </div>
      </div>

      <div class="grid grid-cols-7 gap-2 py-8 mx-4">
        <div class="col-span-1 py-4 px-1 font-bold">
          {{ $t('projects.camera_features') }}
        </div>
        <div class="col-span-6 p-2">
          <div class="flex flex-row p-1">
            <el-checkbox
              name="isEnableBulkCameraScanResultSetting"
              v-model="cameraFeatures.bulkCameraScanResultSetting.isEnable"
              class="ml-0"
            >
              {{ $t('projects.allow_bulk_scan_external') }}
            </el-checkbox>
          </div>

          <div class="flex flex-row p-1">
            <div class="flex items-center">{{ $t('projects.select_delimiter') }}</div>
            <div class="ml-4">
              <el-select
                :disabled="!cameraFeatures.bulkCameraScanResultSetting.isEnable"
                :placeholder="$t('projects.available_delimiters')"
                v-model="cameraFeatures.bulkCameraScanResultSetting.delimiter"
              >
                <el-option
                  class="text-center"
                  v-for="demiliter in listDelimiters"
                  :key="demiliter"
                  :label="demiliter"
                  :value="demiliter"
                />
              </el-select>
            </div>
          </div>
        </div>
      </div>
    </el-form>
  </div>
</template>
<script lang="ts">
import { openMessage } from '@/utils/utils'
import { validateGAMeasurementId } from '@/utils/validator'
import isEmpty from 'lodash/isEmpty'
import { AVAILABLE_DELIMITERS } from 'smartbarcode-web-core/src/utils/enums'
import { ICameraFeatures } from 'smartbarcode-web-core/src/utils/types'
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'

@Options({
  name: 'ProjectSettingsTab',
})
export default class ProjectSettingsTab extends Vue {
  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  showBarcodeSearchPage = false
  showTrackingCountPage = false
  hasGoogleAnalytics = false
  googleAnalyticsMeasurementId = ''
  allowUnmatchedTracking = false
  allowForcePairing = false
  isAutoRecycleEndTrackingPoint = false
  isRecycleChildren = false
  cameraFeatures: ICameraFeatures = {
    bulkCameraScanResultSetting: {
      isEnable: false,
      delimiter: this.listDelimiters[0],
    },
  } as ICameraFeatures

  get listDelimiters() {
    return AVAILABLE_DELIMITERS
  }

  @Watch('hasGoogleAnalytics')
  onHasGAChanged(checked: boolean) {
    if (!checked) this.googleAnalyticsMeasurementId = ''
  }

  @Watch('$store.state.project.projectDetail.mainInfo.googleAnalyticsMeasurementId')
  loadingGAMeasurementId() {
    this.googleAnalyticsMeasurementId =
      this.$store.state.project?.projectDetail.mainInfo?.googleAnalyticsMeasurementId || ''
    this.hasGoogleAnalytics = !isEmpty(this.googleAnalyticsMeasurementId)
  }

  @Watch('$store.state.project.projectDetail.mainInfo.showBarcodeSearchPage')
  loadingShowBarcodeSearchPage() {
    this.showBarcodeSearchPage = this.$store.state.project?.projectDetail.mainInfo?.showBarcodeSearchPage || false
  }

  @Watch('$store.state.project.projectDetail.mainInfo.showTrackingCountPage')
  loadingShowTrackingCountPage() {
    this.showTrackingCountPage = this.$store.state.project?.projectDetail.mainInfo?.showTrackingCountPage || false
  }

  @Watch('$store.state.project.projectDetail.mainInfo.allowUnmatchedTracking')
  loadingUnmatchingPair() {
    this.allowUnmatchedTracking = this.$store.state.project?.projectDetail.mainInfo?.allowUnmatchedTracking || false
  }

  @Watch('$store.state.project.projectDetail.mainInfo.allowForcePairing')
  loadingAllowForcePairing() {
    this.allowForcePairing = this.$store.state.project?.projectDetail.mainInfo?.allowForcePairing || false
  }

  @Watch('$store.state.project.projectDetail.mainInfo.isAutoRecycleEndTrackingPoint')
  loadingIsAutoRecycleEndTrackingPoint() {
    this.isAutoRecycleEndTrackingPoint =
      this.$store.state.project?.projectDetail.mainInfo?.isAutoRecycleEndTrackingPoint || false
  }

  @Watch('$store.state.project.projectDetail.mainInfo.isRecycleChildren')
  loadingIsRecycleChildren() {
    this.isRecycleChildren = this.$store.state.project?.projectDetail.mainInfo?.isRecycleChildren || false
  }

  @Watch('$store.state.project.projectDetail.mainInfo?.cameraFeatures')
  loadingbulkCameraScanResultSetting() {
    this.cameraFeatures.bulkCameraScanResultSetting.isEnable =
      this.$store.state.project?.projectDetail.mainInfo?.cameraFeatures?.bulkCameraScanResultSetting?.isEnable || false

    this.cameraFeatures.bulkCameraScanResultSetting.delimiter =
      this.$store.state.project?.projectDetail.mainInfo?.cameraFeatures?.bulkCameraScanResultSetting?.delimiter || ''
  }

  validate() {
    if (this.hasGoogleAnalytics && !validateGAMeasurementId(this.googleAnalyticsMeasurementId)) {
      openMessage(this.$t('projects.GA_measurement_id_error_message'), 'error')
      return false
    }

    return true
  }
}
</script>

<style lang="scss" scoped>
.project-setting-tab {
  max-height: calc(100vh - 180px);
}
</style>
