<template>
  <div id="trackFlowContainer" class="w-full overflow-auto" ref="tpContainer">
    <div class="text-sm" :style="widthTrackFlowContent ? `width: ${widthTrackFlowContent}px` : ''">
      <div :class="`relative bg-gray-600 ${isDrawing ? 'opacity-0' : ''}`" ref="leaderLineWrapper"></div>
      <div class="m-auto w-min">
        <div :class="`mb-10 w-min`">
          <Draggable
            v-model="_arrTrackPoints"
            tag="transition-group"
            :component-data="draggableComponentData"
            v-bind="draggableBindingData"
            @start="drag = true"
            @end="drag = false"
            item-key="key"
          >
            <template #item="{ element }">
              <div class="ll-node-wrapper py-5">
                <a @click="updateNode(element.key)">
                  <div
                    class="ll-node text-center px-4 py-3 mx-5 leading-normal rounded-full bg-white"
                    :class="
                      `w-${widthNodeItem} ${element.key === currentNode ? 'bg-blue-600 text-white' : ''} ${
                        isDrawing ? 'opacity-0' : ''
                      }`
                    "
                    :id="`track-point-${element.key}`"
                  >
                    {{ element.value.name }}
                  </div>
                </a>
              </div>
            </template>
          </Draggable>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import TrackPointDiagramMixin, { ILine } from '@/components/mixins/TrackPointDiagramMixin.vue'
import { isEmpty } from 'smartbarcode-web-core/src/utils/typeChecker'
import { ITrackPointKeyVal } from 'smartbarcode-web-core/src/utils/types/index'
import isEqual from 'lodash/isEqual'
import ResizeObserver from 'resize-observer-polyfill'
import { mixins, Options } from 'vue-class-component'
import { Emit, Prop, PropSync, Ref, Watch } from 'vue-property-decorator'
import Draggable from 'vuedraggable'

@Options({
  components: { Draggable },
  emits: ['update:currentNode', 'update:arrTrackPoints'],
  name: 'TrackedDataFlow',
})
export default class TrackedDataFlow extends mixins(TrackPointDiagramMixin) {
  @PropSync('arrTrackPoints', { type: Array }) _arrTrackPoints!: ITrackPointKeyVal[]
  @Prop({ type: String, default: '0' }) readonly selectedNodeIndex?: string
  @Prop({ type: String, default: '0' }) readonly startNodeIndex?: string

  @Ref() leaderLineWrapper!: HTMLElement
  @Ref() tpContainer!: HTMLElement

  drag = false

  isDrawing = true
  widthNodeItem = 32
  widthTrackContainer = 0
  widthTrackFlowContent = 0

  currentNode = '' as string
  startNodeData = '' as string
  endNodeIndex = '' as string

  get highlightedColor() {
    return '#2563eb'
  }

  get isReadOnlyMode() {
    return this.$store.getters?.getProjectReadonly
  }

  flowWidth = 0
  resizeObserver: undefined | ResizeObserver = undefined

  get draggableComponentData() {
    return {
      tag: 'div',
      type: 'transition-group',
      name: !this.drag ? 'flip-list' : null,
    }
  }

  get draggableBindingData() {
    return {
      animation: 0,
      disabled: this.isReadOnlyMode,
      ghostClass: 'trackpoint-ghost',
      dragClass: 'trackpoint-drag',
    }
  }

  mounted() {
    this.initResizeObserver()
  }

  initResizeObserver() {
    this.resizeObserver = new ResizeObserver(() => {
      if (this.flowWidth === 0) {
        this.flowWidth = this.tpContainer.offsetWidth
        return
      }

      const diff = this.tpContainer.offsetWidth - this.flowWidth
      if (diff !== 0 && Math.abs(diff) > 10) {
        if (!this.isDrawing) {
          this.isDrawing = true
          this.drawLeaderLine(this._arrTrackPoints)
        }
      }
      this.flowWidth = this.tpContainer.offsetWidth
    })
    this.resizeObserver.observe(this.tpContainer)
  }

  unmounted() {
    if (this.resizeObserver) this.resizeObserver.disconnect()
  }

  @Emit('update:currentNode')
  updateNode(node: string) {
    if (node === this.currentNode) return
    this.currentNode = node
    this._arrTrackPoints = [...this._arrTrackPoints]
    return node
  }

  setCurrentNode(value: string) {
    this.currentNode = value
  }

  setStartNode(value: string) {
    this.startNodeData = value
  }

  setEndNode(value: string) {
    this.endNodeIndex = value
  }

  bodyDrawingClass = 'tps-drawing-leader-line'
  beforeCreate() {
    document.body.classList.add(this.bodyDrawingClass)
  }

  @Watch('isDrawing')
  bodyDrawingClassController() {
    if (!this.isDrawing) {
      document.body.classList.remove(this.bodyDrawingClass)
    } else {
      document.body.classList.add(this.bodyDrawingClass)
    }
  }

  created() {
    this.currentNode = this.selectedNodeIndex ?? ''
    this.startNodeData = this.startNodeIndex ?? ''
  }

  checkAndUpdateOrder(newTps: ITrackPointKeyVal[], oldTps: ITrackPointKeyVal[]) {
    if (isEmpty(newTps) || isEmpty(oldTps)) return
    const newOrder = newTps.map((item) => item.value.order)
    const oldOrder = oldTps.map((item) => item.value.order)
    if (!isEqual(newOrder, oldOrder)) this._arrTrackPoints.forEach((item, idx) => (item.value.order = idx + 1))
  }

  @Watch('_arrTrackPoints', { deep: true, immediate: true })
  onTrackpointsUpdated(newTps: ITrackPointKeyVal[], oldTps: ITrackPointKeyVal[]) {
    document.body.classList.add(this.bodyDrawingClass)
    if (this._arrTrackPoints.length > 0) {
      this.checkAndUpdateOrder(newTps, oldTps)
      this.isDrawing = true
      this.handleDrawLine()
    } else {
      this.isDrawing = false
    }
  }

  isDefaultPosition = false
  isPreviousScroll = false
  previousScrollX = 0
  translateX = 0
  drawLeaderLine(dataTrackPoint: ITrackPointKeyVal[] = []) {
    this.$nextTick(() => {
      const scrollX = this.setContainerWidth(this._arrTrackPoints)
      this.isDefaultPosition = this.widthTrackFlowContent === 0

      const isPositiveScroll = scrollX > 0
      this.translateX = isPositiveScroll ? scrollX : this.isPreviousScroll ? this.previousScrollX : 0
      this.isPreviousScroll = isPositiveScroll
      this.previousScrollX = scrollX

      this.lineGravity = this.getDefaultLineGravity()

      this.llStore = [] as LeaderLine[]
      this.lines = []
      this.$nextTick(() => {
        this.startDraw(dataTrackPoint)

        // Remove old leaderlines
        this.leaderLineWrapper.innerHTML = ''
        this.leaderLineWrapper.style.transform = 'translate(0px, 0px)'

        this.moveLines()
        this.fixWrapperPosition()
        this.processLines()
        const containerEle = document.getElementById('trackFlowContainer')
        if (containerEle && scrollX) containerEle.scrollTo(scrollX, 0)

        this.isDrawing = false
        document.body.classList.remove(this.bodyDrawingClass)
      })
    })
  }

  handleDrawLine() {
    this.$nextTick(() => {
      queueMicrotask(async () => await this.drawLeaderLine(this._arrTrackPoints))
    })
  }

  llStore: LeaderLine[] = [] as LeaderLine[]
  processLines() {
    this.llStore.forEach((ll, idx) => {
      const line: ILine = this.lines?.[idx]
      if (line && line.id?.startsWith(`${this.currentNode}-`)) {
        ll.dash = { animation: true }
        const svgElement = document.getElementById(line.id)
        if (svgElement) {
          svgElement.style.zIndex = this.llStore.length.toString()
        }
      }
      ll.position()
    })
  }

  fixWrapperPosition() {
    const rectWrapper = this.leaderLineWrapper.getBoundingClientRect()
    const bonusTranslateX = this.isDefaultPosition ? this.translateX : 0

    const [X, Y] = [-(rectWrapper.left + bonusTranslateX + window.pageXOffset), -(rectWrapper.top + window.pageYOffset)]

    this.leaderLineWrapper.style.transform = `translate(${X}px, ${Y}px)`
  }

  moveLines() {
    const lines = document.querySelectorAll('body svg.leader-line')
    for (const line of lines) this.leaderLineWrapper.appendChild(line)
  }

  startDraw(dataTrackPoint: ITrackPointKeyVal[] = []) {
    return this.drawLines({
      diagramDirection: 'vertical',
      levelHeight: 20,
      trackpointEntries: dataTrackPoint,
      nodeIdPrefix: 'track-point-',
      lineIdPrefix: '',
      lineColor: '#cecece',
      lineIdAtMiddleLabel: false,
      currentNode: this.currentNode,
      highlightedColor: this.highlightedColor,
    })
  }

  setContainerWidth(dataTrackPoint: ITrackPointKeyVal[] = []) {
    let lineGravityData = this.getDefaultLineGravity()
    let isPrev1stPos = false
    dataTrackPoint.map((node, index) => {
      const trackingPointForms = node.value.trackPointForms || {}
      for (const [nextNodeKey] of Object.entries(trackingPointForms)) {
        const indexNextNode = dataTrackPoint.findIndex((item) => item.key === nextNodeKey)

        const resultCal = this.calculateLeftRightRange(index, indexNextNode, lineGravityData, isPrev1stPos)
        if (resultCal.position !== 'auto') isPrev1stPos = resultCal.position === 'right'

        lineGravityData = resultCal.lineGravityData
      }
    })

    const maxSideWidth = lineGravityData.left > lineGravityData.right ? lineGravityData.left : lineGravityData.right

    const maxContainerWidth = maxSideWidth * 2 + this.widthNodeItem * 4 + 40
    let scrollToX = 0

    const containerEle = document.getElementById('trackFlowContainer')
    if (containerEle) {
      if (containerEle.clientWidth < maxContainerWidth) {
        this.widthTrackFlowContent =
          this.widthTrackFlowContent < maxContainerWidth
            ? maxContainerWidth + 150 /* this 150px for prevent width change too frequently */
            : this.widthTrackFlowContent
      } else {
        this.widthTrackFlowContent = 0
      }
      scrollToX = (this.widthTrackFlowContent - containerEle.clientWidth) / 2
    }

    return scrollToX
  }
}
</script>
<style lang="scss">
body.tps-drawing-leader-line > svg.leader-line {
  display: none;
}

.ll-node-wrapper {
  border-bottom: solid transparent 3px;
}

.flip-list-move {
  transition: transform 0s;
}
.no-move {
  transition: transform 0s;
}
.trackpoint-drag {
  opacity: 0.8;

  .ll-node {
    background-color: #56ccf2;
  }
}

.trackpoint-ghost {
  border-bottom: solid #56ccf2 3px;
}
</style>
