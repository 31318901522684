
import DataVisibilitySettings from '@/components/common/DataVisibilitySettings.vue'
import {
  IActivationDataVisibility,
  ITrackPointKeyVal,
  ITrackPointTracingVisibilities,
} from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, PropSync, Watch } from 'vue-property-decorator'
import ShowCustomFieldsBlock from '@/components/project/blocks/ShowCustomFieldsBlock.vue'
import TrackPointCheckbox from '@/components/common/TrackPointCheckbox.vue'

@Options({
  components: { ShowCustomFieldsBlock, DataVisibilitySettings, TrackPointCheckbox },
  emits: ['update:trackPointTracingVisibilities'],
  name: 'NotLoginTrackHistoryBlock',
})
export default class NotLoginTrackHistoryBlock extends Vue {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() trackingPointDataShown!: ITrackPointKeyVal[]
  @PropSync('activationDataVisibility', { type: Object }) _activationDataVisibility!: IActivationDataVisibility
  @PropSync('trackPointTracingVisibilities', { type: Object }) _trackPointTracingVisibilities!: Record<
    string,
    ITrackPointTracingVisibilities
  >

  hasActivationCustomFields = false
  TPShowCustomFieldsModels = {} as Record<string, boolean>

  get tpTracingVisibilities(): Record<string, boolean> {
    const model = {} as Record<string, boolean>
    const visibleTP = Object.keys(this.trackPointTracingVisibilities)
    Object.keys(this.trackingPointDatasArr).forEach((k) => {
      const tpKey = this.trackingPointDatasArr[Number(k)].key
      model[tpKey] = visibleTP.includes(tpKey)
    })

    return model
  }

  @Watch('_activationDataVisibility', { immediate: true, deep: true })
  onActivationDataVisibilityChanged() {
    if (!this._activationDataVisibility?.showCustomFields) {
      this._activationDataVisibility.showCustomFields = [] as string[]
    }
    this.hasActivationCustomFields = this._activationDataVisibility?.showCustomFields?.length > 0 ?? false
  }

  @Watch('_trackPointTracingVisibilities', { immediate: true, deep: true })
  onTrackPointTracingVisibilitiesChanged() {
    Object.entries(this._trackPointTracingVisibilities).forEach(([k, v]) => {
      if (!v?.showCustomFields) {
        v.showCustomFields = [] as string[]
      }
      this.TPShowCustomFieldsModels[k] = v.showCustomFields.length > 0 ?? false
    })
  }

  onTrackPointTracingVisibilitiesChange(changeKey: string) {
    if (!this._trackPointTracingVisibilities[changeKey]) {
      this._trackPointTracingVisibilities[changeKey] = {
        showLocation: false,
        showTrackedPersonName: false,
        showCustomFields: [] as string[],
        showLinkedBarcodes: false,
      }
    } else delete this._trackPointTracingVisibilities[changeKey]
  }

  onTPShowCustomFieldsModelsChanged(key: string, newVal: boolean) {
    if (newVal) return
    this._trackPointTracingVisibilities[key].showCustomFields = []
  }

  get dataVisibilityItems() {
    return [
      {
        label: this.$t('barcode_type.origin'),
        visibilityModel: this.activationDataVisibility.showOrigin,
        key: 'showOrigin',
      },
      {
        label: this.$t('barcode_type.destination'),
        visibilityModel: this.activationDataVisibility.showDestination,
        key: 'showDestination',
      },
      {
        label: this.$t('tracking_number'),
        visibilityModel: this.activationDataVisibility.showTrackingNumber,
        key: 'showTrackingNumber',
      },
      {
        label: this.$t('barcode_type.external_id'),
        visibilityModel: this.activationDataVisibility.showExternalId,
        key: 'showExternalId',
      },
      {
        label: this.$t('barcode_type.product_info'),
        visibilityModel: this.activationDataVisibility.showProduct,
        key: 'showProduct',
      },
      {
        label: this.$t('record_item_setting'),
        visibilityModel: this.hasActivationCustomFields,
        key: 'showCustomFields',
      },
      {
        label: this.$t('barcode_type.linked_barcodes'),
        visibilityModel: this.activationDataVisibility.showLinkedBarcodes,
        key: 'showLinkedBarcodes',
      },
      {
        label: this.$t('barcode_type.dimension'),
        visibilityModel: this.activationDataVisibility.showDimension,
        key: 'showDimension',
      },
      {
        label: this.$t('barcode_type.linked_barcodes'),
        visibilityModel: this.activationDataVisibility.showLinkedBarcodes,
        key: 'showLinkedBarcodes',
      },
    ]
  }

  onVisibilityChange({ index, value }: { index: number; value: boolean & string[] }) {
    this.dataVisibilityItems.forEach((element, i) => {
      if (index === i) {
        if (element.key === 'showCustomFields') {
          this.hasActivationCustomFields = value
        } else {
          this._activationDataVisibility[element.key as keyof IActivationDataVisibility] = value
        }
      }
    })
  }

  customFieldVisibilitiesChange(newValue: string[]) {
    this._activationDataVisibility.showCustomFields = newValue
  }
}
