
import { fieldRestriction2String, restrictionRule } from '@/utils/helpers'
import { ERestrictionOption } from 'smartbarcode-web-core/src/utils/enums'
import { IAutoUnpairSetting, IFieldRestriction, ITrackPointKeyVal } from 'smartbarcode-web-core/src/utils/types/index'
import { Options, Vue } from 'vue-class-component'
import { InjectReactive, Prop, PropSync } from 'vue-property-decorator'

@Options({
  emits: ['update:pairing', 'update:unpairing'],
  name: 'PairedAllowanceBlock',
})
export default class PairedAllowanceBlock extends Vue {
  @InjectReactive() trackingPointDatasArr!: ITrackPointKeyVal[]
  @InjectReactive() trackingPointDataShown!: ITrackPointKeyVal[]
  @PropSync('pairing', { type: Object }) _pairing?: { [index: string]: IFieldRestriction }
  @PropSync('unpairing', { type: Object }) _unpairing?: { [index: string]: IFieldRestriction }
  @Prop({ type: Boolean, default: false }) readonly allowToPair?: boolean
  @Prop({ type: Boolean, default: false }) readonly isReadOnly?: boolean

  get trackPointActions() {
    return {
      pairing: this.$t('barcode_type.pair_tracking_point'),
      unpairing: this.$t('barcode_type.unpair_tracking_point'),
    }
  }

  get actionTrackPoints(): Record<'pairing' | 'unpairing', Record<string, string>> {
    return {
      pairing: Object.fromEntries(
        this.trackingPointDatasArr.map((v) => [v.key, fieldRestriction2String((this._pairing ?? {})[v.key])])
      ),
      unpairing: Object.fromEntries(
        this.trackingPointDatasArr.map((v) => [v.key, fieldRestriction2String((this._unpairing ?? {})[v.key])])
      ),
    }
  }

  get actionUnpair(): Record<'unpairing', Record<string, IFieldRestriction>> {
    return {
      unpairing: Object.fromEntries(
        this.trackingPointDatasArr.map((v) => {
          let unpairData: IFieldRestriction = {
            isMandatory: false,
            isAvailable: false,
            autoUnpairingSetting: {
              isEnable: false,
              isUnpairDescendent: false,
            },
          }
          if (this._unpairing && this._unpairing[v.key]) {
            unpairData = this._unpairing[v.key]
          }
          const processedUnpairingData: IFieldRestriction = {
            ...unpairData,
            autoUnpairingSetting: {
              isEnable: unpairData?.autoUnpairingSetting?.isEnable || false,
              isUnpairDescendent: unpairData?.autoUnpairingSetting?.isUnpairDescendent || false,
            },
          }
          return [v.key, processedUnpairingData]
        })
      ),
    }
  }

  updateAutoUnpairSetting(action: 'unpairing', key: string, setting: keyof IAutoUnpairSetting, event: unknown) {
    const autoUnpairingSetting = this.actionUnpair[action]?.[key]?.autoUnpairingSetting
    if (autoUnpairingSetting && setting in autoUnpairingSetting) {
      autoUnpairingSetting[setting] = event as boolean
      if (autoUnpairingSetting[setting] === false && setting === 'isEnable') {
        autoUnpairingSetting.isUnpairDescendent = false
      }
    }
    const processedUnpairingData = { ...this.actionUnpair[action][key] }
    this._unpairing = {
      ...this._unpairing,
      [key]: processedUnpairingData,
    }
  }

  isDisabledPairUnpairMandatoryOption(key: string, action: string): boolean {
    return (
      (action === 'unpairing' && this.actionTrackPoints.pairing[key] === ERestrictionOption.MANDATORY) ||
      (action === 'pairing' && this.actionTrackPoints.unpairing[key] === ERestrictionOption.MANDATORY)
    )
  }

  onChangePairOrUnpairTrackingPoints(type: 'pairing' | 'unpairing', trackPointKey: string, value: string) {
    if (type === 'unpairing') {
      const restrictionRuleValue: IFieldRestriction = restrictionRule(value)

      if (!restrictionRuleValue.isAvailable && !restrictionRuleValue.isMandatory) {
        this._unpairing = {
          ...(this._unpairing && this._unpairing),
          [trackPointKey]: {
            ...restrictionRuleValue,
            autoUnpairingSetting: {
              isEnable: false,
              isUnpairDescendent: false,
            },
          },
        }
      } else {
        const updatedData: IFieldRestriction = {
          ...restrictionRuleValue,
          autoUnpairingSetting: {
            isEnable: this._unpairing?.[trackPointKey]?.autoUnpairingSetting?.isEnable || false,
            isUnpairDescendent: this._unpairing?.[trackPointKey]?.autoUnpairingSetting?.isUnpairDescendent || false,
          },
        }
        this._unpairing = {
          ...(this._unpairing && this._unpairing),
          [trackPointKey]: updatedData,
        }
      }

      return
    }
    this[`_${type}`] = {
      ...(this[`_${type}`] && this[`_${type}`]),
      [trackPointKey]: restrictionRule(value),
    }
  }
}
